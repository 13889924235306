<template>
  <div class="table">
    <el-table
      :data="virtualTable"
      default-expand-all
      row-key="id"
      :header-cell-style="{'text-align':'center','background-color':'#FAFAFA'}"
      :cell-style="{'text-align':'center'}"
      height="90%"
    >
      <el-table-column type="index" label="序号" :index="indexMath"></el-table-column>
      <el-table-column prop="xm" label="姓名"></el-table-column>
      <el-table-column prop="xb" label="性别" width="50"></el-table-column>
      <el-table-column prop="sfzhm" label="身份证号码" width="180"></el-table-column>
      <el-table-column prop="lxfs" label="联系方式"></el-table-column>
      <el-table-column prop="cjzd" label="常住地址" width="300"></el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.page"
      :page-sizes="[ 10, 20, 50,100]"
      :page-size="query.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="query.total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  // 结果
  name: 'result',
  props: {
    progress: Object,
  },
  components: {},
  data() {
    return {
      table: [],
      virtualTable: [],
      query: {
        page: 1,
        size: 100,
        total: 0,
      },
    };
  },
  methods: {
    indexMath(index) {
      return index + (this.query.page - 1) * this.query.size + 1;
    },
    // 设置虚拟现实的数据
    setVirtualTable() {
      let { page, size } = this.query;

      this.virtualTable = this.table.slice((page - 1) * size, (page - 1) * size + size);
      console.log(this.virtualTable);
    },
    init(data) {
      this.table = data;
      console.log(this.table);
      this.query.total = data.length;
      return this.setVirtualTable();
    }, // 每页条数变化
    handleSizeChange(data) {
      this.query.size = data;
      this.page = 1;
      return this.setVirtualTable();
    },
    // 当前页数变化
    handleCurrentChange(data) {
      console.log(data);
      this.query.page = data;
      return this.setVirtualTable();
    },
  },
  mounted() {},
  created() {
    // return this.init(this.progress.items);
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.table {
  height: 100%;
  .el-pagination {
    padding-top: 10px;
  }
}
</style>
